<template>
  <div>
    <!-- <h2>总结</h2> -->
    
    <div class="section">
      <h3>C.1 一级指标分级</h3>
      <div>
        <label>自理能力得分：</label>
        <input type="number" v-model="selfCareScoreTotal" disabled/>
      </div>
      <div>
        <label>基础运动能力分：</label>
        <input type="number" v-model="basicMobilityScoreTotal" disabled/>
      </div>
      <div>
        <label>精神状态得分：</label>
        <input type="number" v-model="mentalStateScoreTotal" disabled/>
      </div>
      <div>
        <label>感知觉与社会参与分：</label>
        <input type="number" v-model="sensorySocialScoreTotal" disabled/>
      </div>
    </div>

    <div class="section">
      <h3>C.2 初步等级得分</h3>
      <div>
        <label>初步总分：</label>
        <input type="number" :value="preliminaryScore" disabled />
      </div>
      <div>
        <label>初步等级：</label>
        <input type="text" :value="preliminaryLevel" disabled />
      </div>
    </div>

    <div class="section">
      <h3>C.3 能力等级变更</h3>
      <div>
        <input type="checkbox" v-model="coma" /> 处于昏迷状态者，直接评定为能力完全丧失（完全失能）
      </div>
      <div>
        <input type="checkbox" v-model="dementia" /> 确诊为痴呆（F00-F03）、精神科专科医生诊断的其他精神和行为障碍疾病（F04-F99），在原有能力级别上提高一个等级
      </div>
      <div>
        <input type="checkbox" v-model="riskEvents" /> 近30天内发生过2次及以上照护风险事件（如跌倒、噎食、自杀、自伤、走失等），在原有能力级别上提高一个等级
      </div>
    </div>

    <div class="section">
      <h3>C.4 老年人能力最终等级</h3>
      <div>
        <label>最终总分：</label>
        <input type="number" :value="finalScore" disabled />
      </div>
      <div>
        <label>最终等级：</label>
        <input type="text" :value="finalLevel" disabled />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
    };

  },
  computed: {
      selfCareScore: {
        get() { return this.$store.state.home_older.selfCareScore },
        set(val) { this.$store.commit('home_older/updateSelfCareScore', val) }
      },
      basicMobilityScore:  {
        get() { return this.$store.state.home_older.basicMobilityScore },
        set(val) { this.$store.commit('home_older/updateBasicMobilityScore', val) }
      },
      mentalStateScore: {
        get() { return this.$store.state.home_older.mentalStateScore },
        set(val) { this.$store.commit('home_older/updateMentalStateScore', val) }
      },
      sensorySocialScore: {
        get() { return this.$store.state.home_older.sensorySocialScore },
        set(val) { this.$store.commit('home_older/updateSensorySocialScore', val) }
      },
      coma: {
        get() { return this.$store.state.home_older.coma },
        set(val) { this.$store.commit('home_older/updateComa', val) }
      },
      dementia: {
        get() { return this.$store.state.home_older.dementia },
        set(val) { this.$store.commit('home_older/updateDementia', val) }
      },
      riskEvents: {
        get() { return this.$store.state.home_older.riskEvents },
        set(val) { this.$store.commit('home_older/updateRiskEvents', val) }
      },

    selfCareScoreTotal(){
      return this.selfCareScore.reduce((sum, score) => sum + score, 0)
    },

    basicMobilityScoreTotal(){
      return this.basicMobilityScore.reduce((sum, score) => sum + score, 0)
    },

    mentalStateScoreTotal(){
      return this.mentalStateScore.reduce((sum, score) => sum + score, 0)
    },

    sensorySocialScoreTotal(){
      return this.sensorySocialScore.reduce((sum, score) => sum + score, 0)
    },

    preliminaryScore() {
      console.log("sbtyy")  
      console.log(this.selfCareScore.reduce((sum, score) => sum + score, 0))

      return (
        this.selfCareScore.reduce((sum, score) => sum + score, 0) +
        this.basicMobilityScore.reduce((sum, score) => sum + score, 0) +
        this.mentalStateScore.reduce((sum, score) => sum + score, 0) +
        this.sensorySocialScore.reduce((sum, score) => sum + score, 0)
      );
    },
    preliminaryLevel() {
      const score = this.preliminaryScore;
      if (score >= 90) return "能力完好";
      if (score >= 66) return "能力轻度受损（轻度失能）";
      if (score >= 46) return "能力中度受损（中度失能）";
      if (score >= 30) return "能力重度受损（重度失能）";
      return "能力完全丧失（完全失能）";
    },
    finalScore() {
      let score = this.preliminaryScore;
      if (this.coma) {
        score = 0;
      }
      return score;
    },
    finalLevel() {
      if (this.coma) return "能力完全丧失（完全失能）";
      const baseLevel = this.preliminaryLevel;
      if (this.dementia || this.riskEvents) {
        if (baseLevel === "能力完好") return "能力轻度受损（轻度失能）";
        if (baseLevel === "能力轻度受损（轻度失能）") return "能力中度受损（中度失能）";
        if (baseLevel === "能力中度受损（中度失能）") return "能力重度受损（重度失能）";
        if (baseLevel === "能力重度受损（重度失能）") return "能力完全丧失（完全失能）";
      }
      return baseLevel;
    }
  }
};
</script>

<style scoped>
.section {
  margin-bottom: 10px;
  font-size: 12px;
}


label {
  font-weight: bold;
  font-size: 12px;
  margin-bottom: 10px;
  margin-top: 10px;
}

input[type="number"], 
input[type="text"] {
  margin-left: 10px;
  margin-bottom: 10px;
  padding: 2px;
  width: 200px;
  font-size: 15px;
}
</style>
